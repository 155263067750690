$purple: #4d00f4;
$white: #ffffff;
$gray_input: #f6f6f6;
$gray_text: #5e5e5e;
$gray_head: #1b1b1b;
$gray_bg: #161616;
$black_bg: #000000;
$purple_input: #4200d4;
$purple_message_bg: #c9b2fc;

* {
  box-sizing: border-box;
  margin:0;
  padding:0;
  font-size: 24px;
}
body {
  font-family: Arial, sans-serif;
  background: $purple;
  ::selection {
    background: $purple;
    color:white;
  }
  .bg {
    position: absolute;
    top:100px;
    width: 100%;
    height: calc(100vh - 100px);
    z-index: -1;
    background: $gray_bg;
  }
  .loginForm {
    margin: calc( (100vh - 400px) / 2 ) auto;
    width: 400px;
    padding: 30px;
    border-radius: 5px;
    background: $gray_bg;
    color: $white;
    img {
      width: 100%;
      margin-bottom: 60px;
    }
    input {
      width: 100%;
      padding:10px;
      margin: 10px 0;
      border-radius: 5px;
      border: 0;
      &.error {
        background: $purple_message_bg;
      }
    }
    button {
      width: 100%;
      padding:10px;
      margin: 10px 0;
      border-radius: 5px;
      background: $purple;
      color: $white;
      border: 1px solid $white;
    }
  }
  .message {
    &.error {
      background: pink;
      padding: 37px 10px;
      top: 0;
      position: absolute;
      width: 100%;
      text-align: center;
      transition: all .5s;
      &.unvisible {
        opacity: 0;
        top: -100px;
      }
    }
  }
  .wrapper {
    width: 1400px;
    margin: 40px auto 0;

    img {
      width: 220px;
      padding-left: 30px;
    }
    .exit {
      float: right;
      color: #fff;
      font-size: 14px;
      padding-right: 30px;
    }
    h2 {
      margin: 0;
      font-size: 18px;
      text-transform: uppercase;
      color: #4b4c4e;
    }
    h1 {
      margin: 60px 0 30px;
      font-size: 48px;
      text-transform: uppercase;
      text-align: center;
      color: $white;
      letter-spacing: 15px;
    }
    .cols {
      display: flex;
      .inputs, .results {
        width: calc(50% - 15px);
        padding: 0 30px;
        background: $white;
      }
      .inputs {
        margin-right: 30px;
        .line {
          display: flex;
          justify-content: space-between;
          margin-top:2px;
          * {
            padding: 10px;
            outline: none!important
          }
          .question {
            margin: 30px 0 0;
            width: 100%;
            border: 0;
            transition: background-color 500ms ease-in-out;
            color: $gray_text;
            background-color: $gray_input;
            &::placeholder {
              padding-top: 80px;
              color: lighten($gray_text,50);
              text-align: center;
            }
            &.blink-error {
              background-color: $purple_message_bg;
            }
            &.blink-success {
              background-color: #c8ffb5;
            }
          }
          .listen {
            width: 289px;
            height: 30px;
            padding: 23px 10px 22px;
            color: $white;
            background: $purple;
            transition: all 200ms ease-in-out;
            &:hover {
              background: lighten($purple,5);
              border-color: lighten($purple,5);
            }
          }
          .clear {
            width: 289px;
            height: 25px;
            padding: 25px 10px;
            color: $white;
            background: $purple;
            transition: all 200ms ease-in-out;
            &:hover {
              background: lighten($purple,5);
              border-color: lighten($purple,5);
            }
          }
        }
        .putQuestion {
          width: 309px;
          margin: 30px 0;
          padding: 10px;
          border: 1px solid $purple;
          outline: none!important;
          color: $white;
          background: $purple;
          transition: all 200ms ease-in-out;
          &:hover {
            background: lighten($purple,5);
            border-color: lighten($purple,5);
          }
          &.email {
            margin-left: 7px;
          }
          &[disabled] {
            border-color: $purple_message_bg;
            background-color: $purple_message_bg;
            cursor: not-allowed;
          }

        }
      }
      .results {
        .question_text {
          font-weight: bold;
        }
        .answer_text {
          margin: 30px 0;
          height: 459px;
          background: $gray_input;
          display: flex;
          align-items: center;
          border: 0;
          .answer_content {
            padding: 10px;
            font-style: italic;
            overflow-y: scroll;
            height: 459px;
            width: 100%;
            p {
              margin-bottom: 10px;
              text-align: justify;
            }
          }
        }
        .read {
          display: block;
          width: 100%;
          margin: 30px 0;
          padding: 10px;
          border: 1px solid $purple;
          color: $white;
          background: $purple;
          outline: none!important;
          cursor: pointer;
          transition: all 200ms ease-in-out;
          &:hover {
            background: lighten($purple,5);
            border-color: lighten($purple,5);
          }
          &[disabled] {
            border-color: $purple_message_bg;
            background-color: $purple_message_bg;
            cursor: not-allowed;
          }
        }
      }
    }
    .selectDomainWrapper, .selectPersonWrapper {
      &.error {
        select {
          background: pink;
        }
      }
      padding-top: 30px;
      .selectDomain, .selectDomain:focus-visible,
      .selectPerson, .selectPerson:focus-visible {
        padding: 10px;
        width:100%;
        border: 0;
        background: $gray_input;
        outline: none!important;
      }
      select option {
        padding: 10px;
        text-align: center;
        background: $gray_input;
      }
    }
    .selectPersonWrapper {
      margin: 0 auto;
      width:640px;
      select, select * {
        font-size: 18px;
      }
    }
  }

}

.loading {
  opacity: 0;
  transition: opacity 400ms ease-in-out;
  &.show {
    background: rgba(#000,70%);
    position: fixed;
    top: 0;
    width: 100%;
    height: 100vh;
    opacity: 100%!important;
    .lds-ring {
      display: inline-block;
      position: absolute;
      top: calc(50vh - 40px);
      left: calc(50% - 40px);
      width: 80px;
      height: 80px;
    }
    .lds-ring div {
      box-sizing: border-box;
      display: block;
      position: absolute;
      width: 64px;
      height: 64px;
      margin: 8px;
      border: 8px solid transparent;
      border-radius: 50%;
      animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
      border-top-color: #fff;
    }
    .lds-ring div:nth-child(1) {
      animation-delay: -0.45s;
    }
    .lds-ring div:nth-child(2) {
      animation-delay: -0.3s;
    }
    .lds-ring div:nth-child(3) {
      animation-delay: -0.15s;
    }
  }

  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

}

button, svg {
  cursor: pointer;
}
.hidden {
  display: none!important;
}
